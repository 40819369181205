@use "src/assets/styles/variables" as *
.greetings
  padding: 100px 0 70px
  h1
    margin-bottom: 56px

.wrap
  display: grid
  grid-template-columns: 1fr
  gap: 42px
  h2
    margin-bottom: 28px

.button
  margin: 64px auto 0
  @include max($maxMobile)
    width: 100% !important