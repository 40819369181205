@use "src/assets/styles/variables" as *

.editor
  &Wrap
    position: relative
    grid-column: 1/9
    height: 100%
    @include max($maxTablet)
      grid-column: 1/-1

.tab
  margin-bottom: 30px
  border-radius: 6px
  box-shadow: 0 2px 4px rgba(0,0,0, 0.5)
  background-color: rgba(250,250,250, 1)
  padding: 7px

.wrap
  display: grid
  grid-template-columns: repeat(12, 1fr)
  gap: 30px
  padding-top: 60px
  @include min($minSmall)
    min-height: 80vh

  h2
    margin-bottom: 25px

.aside
  position: relative
  color: #3D3D3D
  grid-column: 10/13
  @include between($minTablet, $maxTablet)
    display: none
  .asideSticky
    position: sticky
    z-index: 1
    top: 125px


.tip
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 24px
  position: fixed
  left: initial
  bottom: 40px
  &:hover
    .tipLogo
      background: $accentLight
      box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)

.tipLogo
  flex-shrink: 0
  width: 48px
  height: 48px
  border-radius: 50%
  background: $accent
  transition: all .2s ease
  svg
    width: 100%
    height: 100%
.connection
  position: fixed
  left: 15px
  right: 15px
  bottom: 30px
  border-radius: 10px
  text-align: center
  z-index: 3
  color: #fff
  animation-fill-mode: forwards
  &.closed
    background: $red
  &.opened
    background: lawngreen
    animation: connection 2s linear 0.2s forwards

.status
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 19px
  position: relative
  margin-top: 48px

  &::before
    content: ''
    display: block
    width: 24px
    height: 24px
    border-radius: 50%
    background-color: lawngreen
    animation: typing 2s linear 0.2s forwards infinite
    position: relative
    left: 4px
  &::after
    content: ''
    width: 32px
    height: 32px
    border-radius: 50%
    border: 1px solid #000
    position: absolute
    box-sizing: border-box
    left: 0
  span
    line-height: 1
  &:not(.saved)
    span
      display: none
  &.saved
    &::before
      animation-iteration-count: 0

@keyframes connection
  0%
    display: block
    opacity: 1
  80%
    opacity: 1
  100%
    display: none
    opacity: 0

@keyframes typing
  0%
    opacity: 1
  50%
    opacity: 0
  100%
    opacity: 1