@use "src/assets/styles/variables" as *

.donation
  margin: 110px 0 120px
  @include max($maxMobile)
    margin: 80px 0


  h1
    text-align: center

.nav
  margin-top: 110px
  display: flex
  justify-content: space-between
  align-items: center
  @include max($maxMobile)
    margin-top: 65px

.methods
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 34px
  white-space: nowrap
  @include max($maxMobile)
    overflow-x: auto
    position: relative
    gap: 20px
    padding: 0 5vw
    margin: 0 -5vw
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none

.method
  input
    display: none

    &:checked + label
      background: #000
      color: #fff

  label
    display: block
    cursor: pointer
    padding: 14px
    border-radius: 9px

    &:hover
      background: #000
      color: #fff

.main
  margin-top: 46px
  border-top: 3px solid #000
  display: grid
  gap: 30px
  grid-template-columns: 6fr 3fr 3fr
  @include between($minSmall, $maxSmall)
    grid-template-columns: 8fr 1fr 3fr
  @include max($maxTablet)
    gap: 80px
    grid-template-columns: repeat(8, 1fr)
  @include max($maxMobile)
    grid-template-columns: repeat(4, 1fr)

.content
  padding-top: 78px
  @include between($minSmall, $maxSmall)
    padding-right: 50px
  @include max($maxTablet)
    grid-column: 1/-1

.divider
  position: relative
  line-height: 0
  &::after
    content: ''
    position: absolute
    top: 0
    right: 110px
    width: 3px
    height: 206px
    background: #000
  @include max($maxTablet)
    grid-column: 1/-1
    &::after
      left: 0
      right: 0
      width: 100%
      height: 3px


.shareButton
  @include max($maxTablet)
    display: none

.sidebar
  line-height: 2
  @include min($minSmall)
    padding-top: 78px
  @include between($minTablet, $maxTablet)
    grid-column: 1/6
  @include max($maxMobile)
    grid-column: 1/-1

.priceBox
  display: grid
  grid-template-columns: repeat(4, 1fr)
  border: 1px solid rgba(151, 151, 151, 1)
  @include max($maxMobile)
    grid-template-columns: 1fr 1fr

.priceItem
  @include min($minTablet)
    &:not(:last-child)
      border-right: 1px solid rgba(151, 151, 151, 1)
  @include max($maxMobile)
    &:nth-child(-n +2)
      border-bottom: 1px solid rgba(151, 151, 151, 1)
    &:nth-child(odd)
      border-right: 1px solid rgba(151, 151, 151, 1)
  input
    display: none
    &:checked + label
      background: $accent

  label
    font: 500 20px/32px $mainFont
    display: flex
    align-items: center
    justify-content: center
    padding: 11px
    cursor: pointer
    white-space: nowrap

    &:hover
      background: #FFE1BA

.priceItemCustom
  border: 1px solid rgba(151, 151, 151, 1)
  position: relative

  input
    text-align: center
    font: 500 20px/32px $mainFont
    background: $accent
    user-select: none

    &:checked + label
      background: $accent

  label
    display: flex
    align-items: center
    justify-content: center
    font: 500 20px/32px $mainFont
    position: absolute
    padding: 11px
    cursor: text
    width: 100%
    height: 100%
    background: $accent

.priceCheckbox
  margin-top: 42px
  input
    display: none
    &:checked + label span
      background: $accent
      &:after
        left: calc(100% - 31px)
  span
    position: relative
    display: inline-flex
    width: 70px
    height: 36px
    border-radius: 18px
    border: 1px solid rgba(151, 151, 151, 1)
    background-color: rgba(239, 239, 239, 1)
    &::after
      content: ''
      position: absolute
      border: 0.5px solid rgba(151, 151, 151, 1)
      box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.31)
      background-color: rgba(255, 255, 255, 1)
      aspect-ratio: 1/1
      border-radius: 50%
      top: 3px
      left: 3px
      bottom: 3px
      transition: all .3s ease

  label
    font: 500 20px/32px $mainFont
    display: inline-flex
    justify-content: flex-start
    align-items: center
    gap: 23px
    cursor: pointer
    &:hover span
      background: $accentLight

.info
  margin-top: 64px
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 30px
  @include max($maxMobile)
    grid-template-columns: 1fr
    gap: 40px

.policy
  margin-top: 64px
  display: grid
  grid-template-columns: 4fr 2fr
  gap: 30px
  @include max($maxMobile)
    grid-template-columns: 1fr

  button
    margin-left: auto

.paymentBlock
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 30px
  span
    font: 400 16px/2 $mainFont
  @include max($maxMobile)
    grid-template-columns: 1fr

.sbp
  aspect-ratio: 1/1
  max-width: 328px

.cryptoList
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 80px 62px
  @include max($maxMobile)
    grid-template-columns: repeat(2, 1fr)
    gap: 60px

.cryptoItem
  display: flex
  align-items: center
  flex-shrink: 0
  gap: 16px
  svg
    width: 42px
    height: 42px
    fill: $accent
    transition: background-color 0.2s ease
  &:hover
    .ltc
      fill: #345D9D
    .zrx
      fill: #231815
    .usdt
      fill: #50AF95
    .eth polygon
      &:nth-child(1)
        fill: #343434
      &:nth-child(2)
        fill: #8C8C8C
      fill: #3C3C3B
      &:nth-child(3)
        fill: #8C8C8C
      &:nth-child(4)
        fill: #141414
      &:nth-child(5)
        fill: #393939
    .etc
      path:nth-child(1)
        fill: #3AB83A
      path:nth-child(2)
        fill: #0B8311
      polygon:nth-child(3)
        fill: #0B8311
      polygon:nth-child(4)
        fill: #146714
    .btc
      fill: #F7931A
    .bth
      fill: #0AC18E

.modal
  border-radius: 9px
  box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
  background-color: #fff
  //padding: 50px 32px 32px
  //max-width: 392px
  z-index: 3
  left: 50%
  transform: translate(-50%, -50%)
  position: absolute
  top: 50%
  width: 100%
  img
    aspect-ratio: 328/337
  .close
    position: absolute
    width: 24px
    height: 24px
    background: $accent
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    line-height: 1
    top: 16px
    right: 16px
    svg
      width: 12px
      height: 11px
.success
  max-width: 540px
  text-align: center
  padding: 60px 60px 55px
  h2
    font: 700 36px/1.2 $mainFont
    padding-bottom: 22px
  p
    font: 400 16px/26px $mainFont
  .closeButton
    margin: 40px auto 0

.qr
  max-width: 620px
  text-align: center
  padding: 60px
  img
    display: block
    padding-bottom: 32px
  @include max($maxMobile)
    padding: 30px
    img
      padding-bottom: 24px

.share
  max-width: 640px
  padding: 60px 60px 70px
  text-align: center
  h2
    padding-bottom: 22px