@use 'src/assets/styles/variables' as *
.wrap
  margin: 200px auto 200px
  @include max($maxMobile)
    margin: 60px auto 80px

.mail
  width: 116px
  height: 86px
  margin-bottom: 80px

.mailWrap
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center

h1
  margin-bottom: 32px