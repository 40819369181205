@use "src/assets/styles/variables" as *
@use "src/assets/styles/fonts" as *

.wrap
  display: flex
  align-items: center
  justify-content: space-between
  gap: 30px
  &.open
    @include min($minSmall)
      justify-content: flex-start
      .login
        margin-left: auto
        width: 100%
  @include max($maxMobile)
    &>*
      width: 33.3%
.logo
  font: 700 28px/24px $logoFont
  max-width: 150px
  @include max($maxMobile)
    font: 700 18px/16px $logoFont

.logoWrap, .login
  min-width: calc(1/6 * 100%)


.lang
  display: flex
  align-items: center
  justify-content: center
  gap: 25px
  cursor: pointer
  padding: 10px
  border-radius: 9px
  transition: all .2s ease
  &:hover
    @include min($minTablet)
      background-color: #EBEBEB
  &.active
    @include max($maxMobile)
      .name
        display: block
  svg
    width: 31px
    height: 26px
    fill: $black
  .name
    font: 400 16px/20px $mainFont
    white-space: nowrap
    @include max($maxMobile)
      display: none

.langWrap
  position: relative
  @include max($maxMobile)
    display: flex
    justify-content: center

.popup
  display: flex
  flex-wrap: wrap
  gap: 27px 12px
  margin-top: 80px
  @include max($maxMobile)
    flex-direction: column
    align-items: center
    gap: 32px
    flex-wrap: nowrap
  &Wrap
    font: 500 18px/21px $mainFont
    border-radius: 9px
    background: #fff
    box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
    z-index: 3
    left: 50%
    transform: translate(-50%)
    position: absolute
    padding: 25px 114px 100px
    top: 25px
    @include min($minSmall)
      width: 928px
    @include between($minTablet, $maxTablet)
      width: calc(100vw - 44px)
      padding: 25px 32px 100px
    @include max($maxMobile)
      width: calc(100vw - 22px)
      padding: 25px 1em 48px
      overflow: hidden
      height: calc(100vh - 23px)
      overflow-y: auto
  button
    @include min($minSmall)
      padding: 10px
      border-radius: 9px
      transition: all .3s ease
      &:hover
        background-color: $black
        color: #fff
  &Button
    display: flex
    align-items: center
    justify-content: center
    gap: 25px
    cursor: pointer
    padding: 10px
    border-radius: 9px
    background-color: $black
    color: #fff
    margin: 0 auto
    svg
      width: 31px
      height: 26px
      fill: #fff

.burger
  width: 32px
  height: 20px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  margin-left: auto
  transition: all .2s ease
  &::before, &::after
    content: ''
    width: 100%
  span, &::before, &::after
    height: 4px
    border-radius: 2px
    display: block
    background: #000
    transition: all .4s ease
  span
    width: 75%
    opacity: 1
  &.open
    width: 24px
    height: 24px
    position: fixed
    z-index: 4
    top: 50px
    right: 5vw
    span
      opacity: 0
    &::before, &::after
      width: 34px
      transform-origin: left
    &::before
      transform-origin: left top
      transform: rotate(405deg) translate(0, -2px)
    &::after
      transform-origin: left bottom
      transform: rotate(315deg) translate(0, 2px)

    &+.burgerMenu
      left: 0

.burgerMenu
  position: fixed
  left: calc(100vw + 50px)
  width: 100vw
  top: 0
  bottom: 0
  z-index: 3
  background-color: #FAFAFA
  padding: 110px 5vw 50px
  transition: left .2s ease
  @include max($maxMobile)
    height: 100vh
    overflow-y: auto
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none