@use 'src/assets/styles/variables' as *

.signUp
  margin: 110px 0 120px
  h1
    text-align: center
  h4
    margin-bottom: 24px

.layout
  display: grid
  grid-template-columns: 1fr
  gap: 64px

.linkRight
  margin-left: auto

.modal
  border-radius: 9px
  box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
  background-color: #fff
  padding: 40px 50px 56px
  max-width: 450px
  z-index: 3
  left: 50%
  transform: translate(-50%, -50%)
  position: absolute
  top: 50%
  width: 100%
  @include max($maxMobile)
    padding: 40px 40px 56px
    max-width: calc(100vw - 30px)
  h2
    text-align: center
  .svg
    display: block
    margin: 0 auto 32px
    width: 112px
    height: 112px