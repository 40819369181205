@use 'src/assets/styles/variables' as *

.wrap
  display: flex
  align-items: center
  justify-content: flex-end
  gap: 8px
  cursor: pointer
  &.active
    @include min($minSmall)
      gap: 0

.name
  font-weight: 500
  text-transform: capitalize

.burger
  width: 24px
  height: 24px
  border-radius: 50%
  background-color: $accent
  position: relative
  transition: box-shadow .2s ease
  flex-shrink: 0
  &:hover
    box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
  span, &::before, &::after
    width: 14px
    height: 2px
    border-radius: 1px
    background-color: $black
    position: absolute
    left: 5px
    transition: all .2s ease
  &::before, &::after
    content: ''
  &:before
    top: calc(50% - 5px)
  &:after
    top: calc(50% + 3px)
  span
    top: calc(50% - 1px)

  &.burgerActive
    span
      display: none
    &::before, &::after
      transform-origin: center center
      top: calc(50% - 1px)
    &:before
      transform: rotate(405deg)
    &:after
      transform: rotate(315deg)

.menu
  display: flex
  align-items: center
  justify-content: flex-end
  gap: 32px
  @include max($maxMobile)
    flex-direction: column
  a
    white-space: nowrap
  &Wrap
    @include min($minTablet)
      opacity: 0
      width: 0
      height: 0
      overflow: hidden
      transition: all .2s ease
      flex-shrink: 0
    @include between($minTablet, $maxTablet)
      transition: unset
      .menuBlur
        z-index: -1
        position: absolute
        opacity: 0.70
        background-color: transparent
        transition: background-color .2s ease
    @include max($maxMobile)
      padding-bottom: 64px
      border-bottom: 3px solid #000
    &.active
      @include min($minTablet)
        width: auto
        height: auto
        opacity: 1
        margin: 0 64px 0 7%
      @include between($minSmall, $maxSmall)
        margin: 0 64px
      @include between($minTablet, $maxTablet)
        position: fixed
        left: 0
        right: 0
        top: 0
        bottom: 0
        margin: 0
        z-index: 3
        display: grid
        grid-template-columns: repeat(8, 1fr)
        gap: 30px
        padding: 20vh 0 30vh
        backdrop-filter: blur(5px)
        .menuBlur
          z-index: 0
          left: 0
          right: 0
          top: 0
          bottom: 0
          background-color: rgba(177,185,193, 1)
        .menu
          height: fit-content
          position: relative
          grid-column: 3/7
          background-color: #fff
          flex-direction: column
          justify-content: flex-start
          padding: 42px 1em 48px
          border-radius: 9px
          box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
          .burger
            position: absolute
            top: 16px
            right: 16px
  &Name
    font: 700 24px/29px $mainFont
    padding-bottom: 10px
