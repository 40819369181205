@use 'src/assets/styles/variables' as *


.loginForm
  margin-top: 64px

.block
  display: grid
  grid-template-columns: 1fr
  gap: 42px

.row
  position: relative

.rowCheckbox
  position: relative
  display: flex
  align-items: flex-start
  justify-content: flex-start
  gap: 24px
  & input[type="checkbox"]
    display: none


.input
  background: transparent
  border-bottom: 2px solid #585E6C
  padding: 16px 0 10px
  font: 400 16px/20px $mainFont
  transition: all .2s ease
  &.focused, &:hover
    border-color: $accent
  &.focused
    & + .label
      top: 0
      font: 400 12px/15px $mainFont
      color: #8D8D8D

.error
  position: absolute
  top: calc(100% + 10px)
  color: $red
  font: 400 14px/17px $mainFont

.label
  position: absolute
  transition: all .2s ease
  top: 12px
  left: 0

.checkbox
  width: 24px
  height: 24px
  border: 2px solid #4D4D4D
  border-radius: 6px
  transition: all .2s ease
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
  &.active
    background: $accent
    border-color: $accent
  svg
    width: 13px
    height: 13px
  &:hover
    background: $accent
    border-color: $accent

.submit
  width: fit-content
  margin: 0 auto
.eye
  position: absolute
  right: 0
  bottom: calc(50% - 10px)
  line-height: 1
  svg
    width: 31px
    height: 20px
    fill: #C0C0C0
    &.active
      fill: $accent

.select
  &.active
    .label
      top: 0
      font: 400 12px/15px $mainFont
      color: #8D8D8D