@use 'src/assets/styles/variables' as *

.footer
  margin-top: auto
  @include max($maxMobile)
    margin-top: 64px

  a
    white-space: nowrap
    @include max($maxMobile)
      text-align: center
      white-space: initial


.year
  @include max($maxMobile)
    display: none
.wrap
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 80px
  @include between($minTablet, $maxTablet)
    gap: 56px
    align-items: initial
  @include max($maxMobile)
    flex-direction: column
    gap: 64px
    &>span
      order: 2

.list
  display: flex
  gap: 17px 32px
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  @include between($minTablet, $maxTablet)
    flex-wrap: wrap
  @include max($maxMobile)
    flex-direction: column
    align-items: center
    order: 0
    gap: 32px

.donation
  @include min($minTablet)
    display: flex
    align-items: center
    gap: 14px
    background: #2C91EA
    color: #fff
    line-height: 20px
    border-radius: 23px
    padding: 12px 14px
    position: fixed
    bottom: 35px
    &:hover
      text-decoration: none
      background: #409FF3
  @include max($maxMobile)
    margin: 0
    order: 1
  .svg
    width: 24px
    height: 22px