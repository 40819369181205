@use "src/assets/styles/variables" as *
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap')

*
  box-sizing: border-box
  outline: none
  &::selection
    background: $accent
    color: #fff

html
  overflow-x: hidden

input, textarea, table, button
  font-family: $mainFont

body
  font: 400 16px/26px $mainFont
  margin: 0
  width: 100%
  padding: 0
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-width: 295px
  position: relative
  color: $black
  min-height: 100vh
  background-color: #fff
html
  height: 100%

header,
footer,
section,
main
  position: relative
button
  font-size: initial
  cursor: pointer
  background: transparent
  padding: 0

.app
  min-height: 100vh
  padding: 48px 0
  display: flex
  flex-direction: column
  @include max($maxMobile)
    padding: 35px 0

.container
  height: 100%
  max-width: 1408px
  margin: 0 auto
  @include max($maxMedium)
    margin: 0 5vw
.container_outer
  display: grid
  grid-template-columns: repeat(12, 1fr)
  gap: 30px
  @include between($minTablet,$maxTablet)
    grid-template-columns: repeat(8, 1fr)
  @include max($maxMobile)
    grid-template-columns: repeat(4, 1fr)
  .container_xl
    grid-column: 2/12
  .container_md
    grid-column: 3/11
  .container_sm
    grid-column: 4/10
  .container_md_s
    @include max($maxSmall)
      grid-column: 2/12
  .container_md_tablet
    @include max($maxTablet)
      grid-column: 1/9
    @include max($maxMobile)
      grid-column: 1/-1
  .container_sm_s
    @include max($maxSmall)
      grid-column: 3/11
  .container_sm_tablet
    @include max($maxTablet)
      grid-column: 2/8
    @include max($maxMobile)
      grid-column: 1/-1

input
  margin: 0
  padding: 0

input:-webkit-autofill,
input:-webkit-autofill:focus
  transition: background-color 600000s 0s, color 600000s 0s

input[type=search]::-ms-clear
  display: none
  width: 0
  height: 0

input[type=search]::-ms-reveal
  display: none
  width: 0
  height: 0

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
  display: none

input::-webkit-calendar-picker-indicator
  opacity: 0

nav
  list-style-type: none
ul
  list-style-type: none
  li
    padding: 0
    list-style-position: inside

ol
  margin: 0
  padding: 0
  li
    list-style-position: inside
    padding: 0
a
  color: $black
  text-decoration: none

h1, h2, h3, h4, p, ul
  margin: 0
  padding: 0

h4
  font: 700 16px/25px $mainFont

table
  width: 100%


img
  display: block
  width: 100%
  height: auto
  &::selection
    background: transparent


.img
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  flex-shrink: 0
  img, picture
    width: 100%
    height: 100%
    display: block
    object-fit: cover

select
  width: 100%
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none
  outline: none
  &::-ms-expand
    display: none

input, textarea
  border: none
  outline: none
  width: 100%

textarea
  resize: none

button
  border: none

address
  font-style: normal

.main
  margin: 60px 0 150px
  @include max(767)
    margin-bottom: 100px

.input
  background: transparent
.nowrap
  white-space: nowrap
.hidden
  display: none
.pointer
  cursor: pointer
.uppercase
  text-transform: uppercase
.underline
  text-decoration: underline
.block
  position: relative
  padding: 120px 0
  @include max(1023)
    padding: 100px 0
.button
  display: flex
  align-items: center
  justify-content: center
  gap: 8px
  cursor: pointer
  white-space: nowrap
  user-select: none
  padding: 10px 20px
  border-radius: 6px
  font: 400 16px/20px $mainFont
  transition: all 0.2s ease
  background: #fff
  &.yellow
    background: $accent
    color: $black
    &:hover
      background-color: $accentLight
      @include min($minSmall)
        box-shadow: 0 12px 10px -7px rgba(0,0,0, 0.5), 0px 5px 4px -2px rgba(172,106,21, 0.255899)
  &.big
    padding: 18px 38px
    font: 500 18px/21px $mainFont
    border-radius: 9px
    width: fit-content
  &:disabled
    background-color: #E6E6E6
    color: initial
  @include max($maxMobile)
    width: 100% !important

svg
  width: 0
  height: 0
  transition: all .2s ease

.link
  color: $textLink
  &:hover
    text-decoration: underline

.h1-display
  font: 500 47px/57px $mainFont

.h1-heading
  font: 700 36px/44px $mainFont
  @include max($maxTablet)
    font: 700 36px/44px $mainFont

.h2-heading
  font: 500 24px/32px $mainFont
  @include max($maxTablet)
    font: 500 24px/32px $mainFont

.col-3
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 32px
  @include between($minTablet, $maxTablet)
    gap: 25px
  @include max($maxMobile)
    gap: 15px

button, a
  -webkit-tap-highlight-color: transparent

.blur
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 3
  background-color: rgba(177,185,193, .7)
  &.active
    backdrop-filter: blur(5px)

.toolbarWrap
  padding-top: 32px
  padding-bottom: 40px
  position: sticky
  top: -8px
  background-color: #fff
  z-index: 1

#toolbar
  //margin-top: 32px
  //position: sticky
  //top: 24px
  background: #FAFAFA
  z-index: 1
  @include max($maxMobile)
    left: 8px
    right: 8px
    .tox-toolbar__group
      padding: 0 5px 0 5px
  .tox-editor-header
    border-radius: 6px
    border: none
    box-shadow: 0 2px 4px rgba(0,0,0, 0.5)


.tox
  svg
    width: 24px
    height: 24px
    @include max($maxMobile)
      width: 20px
      height: 20px
  .tox-toolbar__primary
    width: fit-content
    margin: 0 auto

.mce-content-body
  min-height: 550px
  @include max($maxMobile)
    @media (orientation: portrait)
      min-height: 500px
.no-select
  -webkit-user-select: none /* Chrome/Safari */
  -moz-user-select: none /* Firefox */
  -ms-user-select: none /* IE/Edge */
  user-select: none /* Стандартный синтаксис */
