@use 'src/assets/styles/variables' as *

.profile
  margin: 110px 0 190px
  @include max($maxMobile)
    margin: 80px 0

.profileNav
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 34px
  padding-bottom: 47px
  @include max($maxMobile)
    gap: 20px
  a
    font: 500 24px/32px $mainFont
    padding: 14px
    border-radius: 9px
    color: #000
    background-color: #fff
    transition: all .2s ease
    &:not(.active):hover
      background-color: #EBEBEB
    @include max($maxMobile)
      font-size: 18px
  .active
    background-color: #000000
    color: #fff
.profileChoose
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 30px
  @include max($maxTablet)
    grid-template-columns: 1fr
    gap: 160px

.profilePart
  position: relative
  @include min($minSmall)
    display: grid
    gap: 0 30px
    grid-template-columns: repeat(6, 1fr)
    text-align: center
    padding-bottom: 20px
  &:first-child::after
    content: ''
    width: 3px
    background: #000
    position: absolute
    top: 0
    bottom: 0
    left: calc(100% + 13.5px)
  @include max($maxTablet)
    &:first-child::after
      left: 0
      width: 100%
      height: 3px
      top: calc(100% + 80px)
    @include max($maxMobile)
      text-align: center


  h2
    margin-bottom: 68px
    grid-column: 1/7
  .img, a
    width: 100%
    grid-column: 3/5
    @include between($minSmall, $maxSmall)
      min-width: 190px
  .img
    position: relative
    margin-bottom: 63px
    box-shadow: 0 35px 31px -7px rgba(48,36,20, 0.52182), 0px 18px 17px -6px rgba(29,24,14, 0.6369), 0px 9px 7px -6px rgba(0,0,0, 0.5)
    transition: all .2s ease
    display: block
    border-radius: 3.572327px 1.786164px 1.786164px 3.572327px
    &:hover
      box-shadow: 0 46px 57px -3px rgba(48,36,20, 0.322588), 0px 38px 43px -22px rgba(29,24,14, 0.6369)
    @include between($minTablet, $maxTablet)
      margin: 0
    @include max($maxTablet)
      grid-column: 1/4
      grid-row: 1/4
      max-width: 224px
      width: 100%
    @include max($maxMobile)
      margin: 0 auto 64px
  .title
    color: rgba(255,255,255,0.704233)
    position: absolute
    font: 700 7.85px/1 $mainFont
    color: RGBA(168, 149, 89, 0.5)
    text-align: center
    top: 50px
    left: 5px
    right: 5px
    user-select: none
    color: rgba(255,255,255,0.7)
    text-transform: uppercase
    @include max($maxTablet)
      font-size: 8.45px
  .profileText
    line-height: 2
    grid-column: 2/6
    margin-bottom: 48px
    @include max($maxTablet)
      grid-column: 4/9
  .button
    @include between($minTablet, $maxTablet)
      grid-column: 4/9
    @include max($maxMobile)
      margin: 0 auto
      max-width: fit-content
  .profileInfo
    @include min($minSmall)
      display: contents
    @include between($minTablet, $maxTablet)
      width: 100%
      display: grid
      grid-template-columns: repeat(8, 1fr)
      gap: 0 30px
.wrap
  display: grid
  gap: 30px
  padding-top: 80px
  border-top: 3px solid #000
  position: relative
  &::after
    content: ''
    display: block
    position: absolute
    background: #000
    width: 3px
    @include min($minSmall)
      top: 0
      height: 216px
      left: calc(100%/12*8)

  &.empty
    &::after
      @include min($minSmall)
        left: calc(100%/12*4)
      @include between($minTablet, $maxTablet)
        top: 0
        height: 206px
        left: calc(100%/9*4)

  @include min($minSmall)
    grid-template-columns: repeat(12, 1fr)
  @include between($minTablet, $maxTablet)
    grid-template-columns: repeat(8, 1fr)
    gap: 160px 30px
  @include max($maxMobile)
    grid-template-columns: 1fr
    gap: 0


  &.filled
    .book
      @include max($maxMobile)
        grid-row: 2/3
        & + a
          grid-row: 3/4

  &.empty
    .book
      @include max($maxMobile)
        grid-column: 1/-1
.book
  @include min($minTablet)
    grid-column: 1/4
    position: relative
    margin-right: 15px
  @include max($maxMobile)
    margin: 0 auto 68px
    max-width: 232px
  .img
    position: relative
    transition: all .2s ease
    display: block
    border-radius: 3.572327px 1.786164px 1.786164px 3.572327px
    box-shadow: 0 35px 31px -7px rgba(48,36,20, 0.52182), 0px 18px 17px -6px rgba(29,24,14, 0.6369), 0px 9px 7px -6px rgba(0,0,0, 0.5)
    @include min($minSmall)
      &:hover
        box-shadow: 0 46px 57px -3px rgba(48,36,20, 0.322588), 0px 38px 43px -22px rgba(29,24,14, 0.6369)
  .title
    position: absolute
    font: 700 14px/17px $mainFont
    color: RGBA(168, 149, 89, 0.5)
    text-align: center
    top: 50px
    left: 5px
    right: 5px
    user-select: none
    color: rgba(255,255,255,0.7)
    text-transform: uppercase
  .pages
    font: 400 14px/24px $mainFont
    color: RGB(61, 61, 61)
    margin-top: 32px
    text-align: center

.info
  @include min($minTablet)
    grid-column: 4/10
  @include between($minTablet, $maxTablet)
    grid-column: 4/9
  @include max($maxMobile)
    grid-row: 1/2

  h1
    margin-bottom: 22px
    text-transform: capitalize
    @include min($minSmall)
      max-width: calc( (100% - 5*30px)/6 * 4 + 3* 30px )

.date
  font: 500 24px/32px $mainFont
  margin-bottom: 60px
  @include min($minSmall)
    max-width: calc( (100% - 5*30px)/6 * 4 + 3* 30px )
  @include max($maxMobile)
    margin-bottom: 40px

.sidebar
  display: flex
  flex-direction: column
  align-items: center
  gap: 64px
  @include min($minTablet)
    grid-column: 10/13
  @include between($minTablet, $maxTablet)
    grid-column: 1/-1
    display: grid
    grid-template-columns: repeat(8, 1fr)
    gap: 30px
    align-items: flex-start
    button
      grid-column: 6/9
  @include max($maxTablet)
    position: relative
    &::after
      content: ''
      position: absolute
      left: 0
      right: 0
      bottom: calc(100% + 64px)
      height: 3px
      background: #000
  @include max($maxMobile)
    grid-row: 4/5
    margin-top: 150px

  &Text
    line-height: 32px
    width: 100%
    @include between($minTablet, $maxTablet)
      grid-column: 1/6
    p:not(:last-child)
      margin-bottom: 42px

.emptyText
  @include min($minSmall)
    grid-column: 6/11
  @include between($minTablet, $maxTablet)
    grid-column: 5/9
  p
    margin-bottom: 50px
    line-height: 2