@use 'src/assets/styles/variables' as *


.headingWrap
  height: calc(100vh - 90px)
  position: relative

.heading
  color: $red
  font: 400 160px/1 "Tinos-Regular"
  display: inline-block
  letter-spacing: 3px
  text-align: center
  left: 0
  right: 0
  width: 100%
  text-transform: uppercase
  white-space: nowrap
  position: sticky

.mainText
  opacity: 1
  color: #000
  letter-spacing: 0
  text-align: justify
  font: 400 50px/61px $mainFont
  padding: 100px 0 200px


.books
  display: grid
  grid-template-columns: repeat(12, 1fr)
  gap: 30px
  margin-bottom: 200px
.book
  &:nth-child(1)
    grid-column: 2/6
  &:nth-child(2)
    grid-column: 8/12
  .img
    position: relative
    transition: all .2s ease
    display: block
    border-radius: 3.572327px 1.786164px 1.786164px 3.572327px
    box-shadow: 0 35px 31px -7px rgba(48,36,20, 0.52182), 0px 18px 17px -6px rgba(29,24,14, 0.6369), 0px 9px 7px -6px rgba(0,0,0, 0.5)
    &:hover
      box-shadow: 0 46px 57px -3px rgba(48,36,20, 0.322588), 0px 38px 43px -22px rgba(29,24,14, 0.6369)
  .title
    position: absolute
    font: 700 14px/17px $mainFont
    color: RGBA(168, 149, 89, 0.5)
    text-align: center
    top: 50px
    left: 5px
    right: 5px
    user-select: none
    color: rgba(255,255,255,0.7)
    text-transform: uppercase
  a
    margin: 85px auto 0