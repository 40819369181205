@use 'src/assets/styles/variables' as *
.page
  margin: 100px 0 150px
  h1, h2, h3
    text-align: left
  h1
    margin-bottom: 56px
    @include between($minTablet, $maxTablet)
      margin-bottom: 40px
    @include max($maxMobile)
      margin-bottom: 30px
  h2
    margin-bottom: 28px
    @include max($maxTablet)
      margin-bottom: 1em
  .block:not(:last-child)
    margin-bottom: 42px
  p:not(:last-child)
    margin-bottom: 16px
  li:before
    content: "— "
.table
  .tableRow
    display: grid
    grid-template-columns: 3fr 7fr
    &:nth-child(even)
      background: #F0EEEE
  .tableDate
    padding: 30px
    &:nth-child(1)
      font-weight: 700
    @include between($minTablet, $maxTablet)
      padding: 15px
    @include max($maxMobile)
      padding: 10px