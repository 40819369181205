@font-face
    font-family: 'Gotham Pro'
    src: local("Gotham Pro"), url('/fonts/GothamPro-Bold.woff2') format("woff2")
    font-weight: bold
    font-display: swap


@font-face
    font-family: 'Tinos-Regular'
    src: local("Gotham Pro"), url('/fonts/Tinos-Regular.woff2') format("woff2")
    font-weight: bold
    font-display: swap